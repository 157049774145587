import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Navbar from "./Components/Navbar";

import Home from "./Pages/Home";
import Software from "./Pages/Software";
import AccountPage from "./Pages/Account";
import Register from "./Pages/account/Register";
import Login from "./Pages/account/Login";
import Profile from "./Pages/account/Profile";
// import Footer from './Components/Footer';

import { Client, Account } from "appwrite";

const client = new Client();

const account = new Account(client);

client
    .setEndpoint("https://obosoft.it:8056/v1") // Your API Endpoint
    .setProject("6441c6e7d6448edcc109"); // Your project ID

function App() {
    const [sidebar, setSideBar] = useState(false);
    const [user, setUser] = useState(null);

    // Create a ref with copy of user username
    const userRef = React.useRef('');

    useEffect(() => {

        // Check if user is logged in
        account.get().then((response) => {
            setUser(response)
        }, (error) => {
            setUser(false);
        });

        // Check if user is logged in requesting the api /user/loggedIn
        // fetch("http://192.168.178.144:8055/api/user/loggedIn", {
        //     method: "GET",
        //     credentials: "include",
        // })
        //     .then((res) => res.json())
        //     .then((data) => {
        //         if (data.code === 200){
        //             setUser(data.user);
        //         }
        //         else {
        //             setUser(false);
        //         }
        //     }
        // );
    }, []);

    return (
        <div
            className={
                "flex flex-col h-full" + (sidebar ? " overflow-hidden" : "")
            }
        >
            {/* <Sidebar /> */}
            <BrowserRouter>
                <Navbar setSideBar={setSideBar} sideBar={sidebar} user={user} />
                <Routes className="flex-grow ">
                    <Route path="/" element={<Home />} />
                    <Route path="/software" element={<Software />} />
                    <Route
                        path="/cheats"
                        element={<Navigate to="/"></Navigate>}
                    />
                    <Route
                        path="/account"
                        element={<AccountPage user={user} />}
                    />
                    <Route
                        path="/profile"
                        element={<Profile user={user} />}
                    />
                    <Route
                        path="/logout"
                        element={<Login setLoggedIn={setUser} user={user} successMsg={'Successfully logged out from your account'}/>}
                    />
                    <Route path="/login" element={<Login setLoggedIn={setUser} user={user} />} />
                    <Route path="/register" element={<Register setLoggedIn={setUser} user={user} />} />
                    <Route path="*" element={<Navigate to="/"></Navigate>} />
                </Routes>
            </BrowserRouter>
            {/* <Footer /> */}
        </div>
    );
}

export default App;
