import React from 'react'
import { useLocation } from 'react-router-dom'

function Sidebar( props ) {
    return (
        <div className={'sm:hidden z-10 w-full grow h-full ' + (props.sidebar ? 'flex' : 'hidden')}>
            {/* Blurred black background */}
            <div className='bg-stone-900/60 backdrop-blur-sm w-full h-full'>
                 {/* Sidebar */}
                <div className={'z-20 flex flex-col items-center  bg-stone-900 h-full w-2/3 ml-auto px-4 py-4 gap-2'}>
                    <SidebarNavlink link='/' text='Home' setSideBar={props.setSideBar} />
                    <SidebarNavlink link='/software' text='Software' setSideBar={props.setSideBar} />
                    <SidebarNavlink link='/account' text='Account' setSideBar={props.setSideBar} />
                </div>

            </div> 
        </div>
    )
}

function SidebarNavlink(props) {

    // Create a isCurrentPage state variable and get the current page from the url
    const location = useLocation();
    const isActive = location.pathname === props.link ? true : false;

    return (
    <a 
        href={props.link} 
        onClick={() => props.setSideBar(false)} 
        className={'' + (isActive ? ' sidebar_button_active' : 'sidebar_button')}
    >
        { props.text }
    </a>
    )
}


export default Sidebar