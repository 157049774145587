import React, { useEffect, useRef } from 'react'
import { Navigate } from 'react-router-dom'

import ReactLoading from 'react-loading';

import { Client, Account } from "appwrite";

const client = new Client();

const account = new Account(client);

client
    .setEndpoint("https://obosoft.it:8056/v1") // Your API Endpoint
    .setProject("6441c6e7d6448edcc109"); // Your project ID

function Profile(props) {
    return (
        <div className='mt-20 flex flex-col items-center justify-center p-5'>
        {
            props.user === null ? (
                <div className='flex flex-col gap-5 items-center justify-center grow'>
                    <ReactLoading type={'spinningBubbles'} color={'white'} height={64} width={64} />
                    <div className='text-2xl'>Loading ...</div>
                </div>
            ) : (
                props.user === false ? (
                    <Navigate to='/login' />
                ) :
                    <UserProfile data={props.user} />
            )
        }
        </div>
    )
}

// PROFILE COMPONENT

function UserProfile(props) {
    let user = props.data;

    return (
        <div className='flex flex-col gap-5 items-center justify-center w-full sm:w-5/6'>
            <div className='text-4xl flex flex-col sm:flex-row gap-3 items-center justify-center sm:items-start md:gap-5'>
                {
                    user.prefs.image ? (
                        <img src={user.prefs.image} alt='Profile' className='rounded-full w-32 h-32' />
                    ) : (
                        <div className='rounded-full w-32 h-32 bg-gray-500'>
                            <img src='/images/guest.png' alt='Profile' className='rounded-full w-32 h-32' />
                        </div>
                    )
                }
                <div className='flex flex-col gap-1 text-center sm:text-left justify-center h-full'>
                    <div className='text-3xl'>Welcome, <span className='text-orange-500'>{user.name}</span>!</div>
                    {
                        user.prefs.accountType ? (
                            <div className='text-base'>Account type: <span className='text-orange-500'>{user.prefs.accountType}</span></div>
                        ) : (
                            <div className='text-base'>Account type: <span className='text-orange-500'>User</span></div>
                        )
                    }
                    <div className='text-base'>Member since: <span className='text-orange-500'>{
                        user.registration.split('T')[0].split('-').reverse().join('/')
                    }</span></div>
                </div>
                
            </div>
            
            <div className='' >This is your dashboard! From here you can manage your profile and your software licenses!</div>
            <button 
                className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
                onClick={() => {
                    account.deleteSession('current')
                        .then(() => {
                            window.location.href = '/login';
                        }, error => {
                            console.log(error);
                        }
                    );
                }}
            >
                Logout
            </button>

            {
                user.prefs.accountType === 'Developer' ? (
                    <button
                        className='bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded'
                        onClick={() => {
                            window.location.href = '/developer/add-software';
                        }}
                    >
                        Add a software
                    </button>
                ) : null
            }
        </div>
    )
}

export default Profile