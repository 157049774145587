import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'

import { Client, Account } from "appwrite";

const client = new Client();

const account = new Account(client);

client
    .setEndpoint('https://obosoft.it:8056/v1') // Your API Endpoint
    .setProject('6441c6e7d6448edcc109'); // Your project ID

function Login(props) {

    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    return (
        <div className='mt-20 flex flex-col '>

            {
                props.user ? (
                    <Navigate to='/profile' />
                ) : null
            }

            <div className='text-3xl font-normal text-center py-5'> Login in your account </div>
            {
                props.successMsg ? (
                   <SuccessMsg msg={props.successMsg} />
                ) : null
            }
            <div className='flex flex-col items-center'>
                <form 
                    className='flex flex-col w-3/4 sm:w-[400px] gap-5' 
                    autoComplete='off'

                    onSubmit={(e) => {
                        e.preventDefault();
                        let email = e.target[0].value;
                        let password = e.target[1].value;

                        const promise = account.createEmailSession(email, password);

                        promise.then(response => {
                            window.location.href = '/profile';
                        }, error => {
                            setErrorMsg(error.message);
                        });

                        // fetch('http://192.168.178.144:8055/api/user/login', {
                        //     method: 'POST',
                        //     credentials: 'include',
                        //     headers: {
                        //         'Content-Type': 'application/json'
                        //     },
                        //     body: JSON.stringify({
                        //         email: email,
                        //         password: password
                        //     })
                        // })
                        //     .then(res => res.json())
                        //     .then(data => {
                        //         if (data.code === 200) {
                        //             // Redirect to home page
                        //             window.location.href = '/';
                        //         } else {
                        //             setErrorMsg(data.message);
                        //         }
                        //     })
                    }}
                >
                    <div className='flex flex-col gap-0 text-start hover:text-orange-500'>
                        <label className='text-lg font-normal px-1'> Email </label>
                        <input autoComplete='off' className='border-b border-neutral-700 hover:border-orange-500 active:border-orange-500 p-2 bg-neutral-900 outline-0' type='email' />
                    </div>
                
                    <div className='flex flex-col gap-0 text-start hover:text-orange-500'>
                        <label className='text-lg font-normal px-1'> Password </label>
                        <input autoComplete='new-password' className='border-b border-neutral-700 hover:border-orange-500 active:border-orange-500 p-2 bg-neutral-900 outline-0' type='password' />
                    </div>

                    <ErrorMsg msg={errorMsg} />

                    <button className='text-zinc-300 flex flex-row justify-between items-center hover:border-b border-stone-700 hover:text-orange-500 text-2xl font-light py-2'> 
                        Login 
                        <span className='material-symbols-outlined hover:no-decoration'> arrow_forward </span>
                    </button>

                    <div className='flex flex-col justify-center mt-2 gap-1'>
                        <a href='/register' className='text-zinc-300 flex flex-row justify-between items-center hover:border-b border-stone-700 hover:text-orange-500 py-1 text-sm'> 
                            <span>Don't have an account? Register now!</span>
                            <span className='material-symbols-outlined hover:no-decoration'> person_add </span>
                        </a>

                        <a href='/register' className='text-zinc-300 flex flex-row justify-between items-center hover:border-b border-stone-700 hover:text-orange-500 py-1 text-sm'> 
                            <span>Forgot password? </span>
                            {/* An appropriate icon */}
                            <span className='material-symbols-outlined hover:no-decoration'> Help </span>
                        </a>
                    </div>
                </form>
            </div>
        </div>
    )
}

function ErrorMsg(props) {
    if (props.msg) {
        return (
            <div className='flex flex-row justify-center items-center gap-1 text-red-500'>
                <span className='material-symbols-outlined'> error </span>
                <span className='text-sm'> {props.msg} </span>
            </div>
        )
    } else {
        return <></>
    }
}

function SuccessMsg(props) {
    if (props.msg) {
        return (
            <div className="flex flex-row justify-center items-center gap-1 text-green-500">
                <span className="material-symbols-outlined">
                    {" "}
                    check_circle{" "}
                </span>
                <span className="text-sm"> {props.msg} </span>
            </div>
        );
    } else {
        return <></>;
    }
}

export default Login