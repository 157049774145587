import React, { useEffect } from 'react'

import Card from '../Components/Card'
import ReactLoading from 'react-loading';

import { Client, Databases } from "appwrite";

const client = new Client();

const database = new Databases(client);

const db_id = "6441d733de9b8ae7a88b";
const software_collection_id = "6441d73f93877622e8f2";

client
	.setEndpoint("https://obosoft.it:8056/v1") // Your API Endpoint
	.setProject("6441c6e7d6448edcc109"); // Your project ID

// Generate 10 software objects

// const software = [
//     {
//         title: 'Windows Activator',
//         description: 'Active any of your Windows OS without buying any key!',
//         link: '.',
//         download: true
//     },
//     {
//         title: 'Spotify Downloader',
//         description: 'Get the MP3 file of any spotify track or playlist!',
//         link: '.',
//         download: true
//     },
//     {
//         title: 'YouTube Downloader',
//         description: 'Get the MP4 file of any YouTube video!',
//         link: '.',
//         download: true
//     },
//     {
//         title: 'Instagram Downloader',
//         description: 'Get the MP4 file of any Instagram video!',
//         link: '.',
//         download: true
//     },
//     {
//         title: 'Facebook Downloader',
//         description: 'Get the MP4 file of any Facebook video!',
//         link: '.',
//         download: true
//     },
//     {
//         title: 'Twitter Downloader',
//         description: 'Get the MP4 file of any Twitter video!',
//         link: '.',
//         download: true
//     },
//     {
//         title: 'Windows Activator',
//         description: 'Active any of your Windows OS without buying any key!',
//         link: '.',
//         download: true
//     },
//     {
//         title: 'Spotify Downloader',
//         description: 'Get the MP3 file of any spotify track or playlist!',
//         link: '.',
//         download: true
//     },
//     {
//         title: 'YouTube Downloader',
//         description: 'Get the MP4 file of any YouTube video!',
//         link: '.',
//         download: true
//     },
//     {
//         title: 'Instagram Downloader',
//         description: 'Get the MP4 file of any Instagram video!',
//         link: '.',
//         download: true
//     },
//     {
//         title: 'Facebook Downloader',
//         description: 'Get the MP4 file of any Facebook video!',
//         link: '.',
//         download: true
//     },
//     {
//         title: 'Twitter Downloader',
//         description: 'Get the MP4 file of any Twitter video!',
//         link: '.',
//         download: true
//     },
// ]

function Software() {

	// Create a state for software
	const [software, setSoftware] = React.useState([])

	useEffect(() => {
		const promise = database.listDocuments(db_id, software_collection_id);

		promise.then(function (response) {
			console.table(response); // Success
			setSoftware(response.documents);
		}, function (error) {
			console.log(error); // Failure
		});
	}, [])
		

	// Fetch software from API
	// React.useEffect(() => {
	// 	fetch('http://192.168.178.144:8055/api/software/list')
	// 		.then(res => res.json())
	// 		.then(data => {
	// 			setSoftware(data)
	// 		})
	// }, [])

  return (
	<div className='flex flex-col items-center justify-center mt-20'>
		<div className='flex flex-col items-center text-3xl sm:text-4xl py-5'>Software collection</div>
		<div className='flex flex-col items-center sm:flex-row sm:items-stretch sm:justify-center sm:py-5 sm:px-10 gap-10 w-full flex-wrap grow'>
			
			{/* While software not loaded yet, display "Loading...". When loaded, map throug software and display a card */}

			{
				software.length === 0 || software === null ? (
					<div className='flex flex-col gap-5 items-center justify-center grow'>
						<ReactLoading type={'spinningBubbles'} color={'white'} height={64} width={64} />
						<div className='text-2xl'>Loading ...</div>
					</div>
				) : (
					software.map((software, index) => (
						<Card key={index} title={software.title} description={software.description} link={software.link} download={software.download} image={software.image} requireLicense={software.requireLicense} license={software.license} />
					))
				)
			}

			
			
			{/* {software.map((software, index) => (
				<Card key={index} title={software.title} description={software.description} link={software.link} download={software.download} />
			))} */}
			
		</div>
	</div>
  )
}

export default Software