import React from 'react'
import Card from '../Components/Card'

function Home() {
  return (
    <div className='flex flex-col w-full justify-center items-center mt-20'>
        <div className='flex flex-col items-center text-2xl py-10'>
            <span className='text-4xl sm:text-5xl md:text-6xl xl:text-7xl font-semibold text-orange-500'>Welcome to OboSoft!</span>
            <span className='text-lg sm:text-xl md:text-2xl xl:text-3xl font-normal py-1'>Empowering your digital world since 2015</span>
        </div>
        <div className='flex flex-col justify-center items-center sm:flex-row sm:flex-wrap h-fit py-5 px-10 gap-10 lg:gap-15 w-full sm:items-stretch'>
            <Card title='Software' 
                description='A section containing all of our software products.' 
                home
            />
            <Card title='Cheats' 
                description='A section containing all of our cheats for different games.'
                home
            />
            <Card title='Utilities' 
                description='Here you can find all of our utilities that can help you with your daily tasks.' 
                home
            />
        </div>
    </div>
  )
}

export default Home