import React from 'react'

function Card(props) {
  return (
    <div className={'flex flex-col backdrop-opacity-60 rounded-lg w-80 sm:w-80 md:w=96 bg-neutral-800 flex-col overflow-hidden transition duration-300 ease-in-out cursor-pointer'}>
        {
            props.image ?
                <img src={props.image} alt={props.title} className='w-full h-40 sm:h-48 md:h-56 object-cover' />
            :
            <img 
                src={'./images/cards/' + props.title.toLowerCase() + '.png'} alt={props.title} 
                className='w-full h-2/3 rounded-lg object-cover object-center h-52' 
            />
        }

        <div className='flex flex-col items-center justify-center px-3 py-2 text-3xl w-full h-fit'>
            {props.title}
            <span className='text-sm sm:text-lg my-2 text-center'>
                {props.description}
            </span>
        </div>

        <div className='w-full text-center mt-auto self-stretch'>
            {props.link ?
                <a className='text-xl flex flex-row gap-2 items-center justify-center bg-orange-500 p-3 hover:bg-orange-600  active:bg-orange-700 ' href={props.link}>
                    { props.download ? 
                        props.requireLicense ? 
                            'Download (License required)'
                            :
                            'Download'
                    :
                        'More info'
                    }
                    <span className='material-symbols-outlined'>
                        { props.download ? 'get_app' : 'info'}
                    </span>
                </a>
            :
            <a className='text-xl flex flex-row gap-2 items-center justify-center bg-orange-500 p-3 hover:bg-orange-600  active:bg-orange-700' href={'/' + props.title.toLowerCase()}>
                Go to {props.title}
                <span className='material-symbols-outlined'>
                    arrow_right_alt
                </span>
            </a>
            }
        </div>
        
    </div>
  )
}

export default Card