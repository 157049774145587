import React from 'react'
import { useLocation } from 'react-router-dom'

import Sidebar from './Sidebar'

function Navbar(props) {
    return (
        <div className={'absolute flex flex-col h-full w-full ' + (props.sideBar ? '' : 'pointer-events-none')}>
            <div className='flex flex-row items-center justify-center bg-neutral-800 shadow-md px-0 pointer-events-auto'>
                <div className="hidden sm:flex sm:w-full xl:w-4/5 h-fit p-2 flex flex-row items-center justify-between">
                    <img src='./images/obo-soft-orange.png' alt='obo-soft-logo' className='w-60 px-2 py-1 cursor-pointer' />
                    <ul className="flex flex-row items-center gap-3 px-2 m:text-lg md:text-xl md:font-light">
                        <NavbarLink link='/' text='Home' />
                        <NavbarLink link='/software' text='Software' />
                        {/* <NavbarLink link='/cheats' text='Cheats' /> */}
                        {
                            props.user === null ? <NavbarLink link='/profile' text='Account' /> :
                            props.user === false ? <NavbarLink link='/login' text='Login' /> :
                            <NavbarLink link='/profile' text={props.user.name} />
                        }
                        
                    </ul>
                </div>
                <div className="flex sm:hidden h-fit w-full p-2 flex flex-row items-center justify-between">
                    <img src='./images/obo-soft-orange.png' alt='obo-soft-logo' className='w-48 px-2 py-1 cursor-pointer' />
                    <span className="material-symbols-outlined text-2xl scale-150 px-1 mx-2 rounded-xl hover:bg-neutral-700 active:bg-neutral-600 hover:cursor-pointer"
                        onClick={() => props.setSideBar(!props.sideBar)}
                    > { props.sideBar ? 'close' : 'menu'} </span>
                </div>
            </div>
            <Sidebar sidebar={props.sideBar} setSideBar={props.setSideBar} />
        </div>
    )
}

function NavbarLink(props) {

    const location = useLocation();
    const isActive = location.pathname === props.link ? true : false;

    return (
        <a href={props.link} className={'' + (isActive ? 'page_button_active' : 'page_button')} > { props.text } </a>
    )
}

export default Navbar