import React, { useState } from "react";
import { Navigate } from "react-router-dom";

import { Client, Account, ID } from 'appwrite';

const client = new Client()
            .setEndpoint('https://obosoft.it:8056/v1') // Your API Endpoint
            .setProject('6441c6e7d6448edcc109'); // Your project ID

function Register(props) {
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    return (
        <div className="mt-20 flex flex-col gap-5">

            {
                props.user ? (
                    <Navigate to='/profile' />
                ) : null
            }

            <div className="text-3xl font-normal text-center py-5">
                {" "}
                Register your account{" "}
            </div>
            <div className="flex flex-col items-center">
                <form
                    className="flex flex-col w-3/4 sm:w-[400px] gap-5"
                    autoComplete="off"
                    onSubmit={(e) => {
                        e.preventDefault();
                        let username = e.target[0].value;
                        let email = e.target[1].value;
                        let password = e.target[2].value;
                        let confirmPassword = e.target[3].value;

                        if (password !== confirmPassword) {
                            setErrorMsg("Passwords do not match");
                            return;
                        }

                        const account = new Account(client);
                        // Register User
                        account.create(
                            ID.unique(),
                            email,
                            password,
                            username
                        ).then(response => {
                            // create session
                            const promise = account.createEmailSession(email, password);

                            promise.then(response => {
                                window.location.href = '/profile';
                            }, error => {
                                setErrorMsg(error.message);
                            });
                        }, error => {
                            console.log(error);
                            // setErrorMsg(error.message);
                        });

                        // fetch("http://192.168.178.144:8055/api/user/register", {
                        //     method: "POST",
                        //     credentials: "include",
                        //     headers: {
                        //         "Content-Type": "application/json",
                        //     },
                        //     body: JSON.stringify({
                        //         username: username,
                        //         email: email,
                        //         password: password,
                        //     }),
                        // })
                        //     .then((res) => res.json())
                        //     .then((data) => {
                        //         if (data.code === 200) {
                        //             setSuccessMsg(data.message);
                        //             // Redirect to login page after 3 seconds
                        //             setTimeout(() => {
                        //                 window.location.href = "/login";
                        //             }, 3000);

                        //             // Clear form fields
                        //             e.target[0].value = "";
                        //             e.target[1].value = "";
                        //             e.target[2].value = "";
                        //             e.target[3].value = "";
                        //         } else {
                        //             setErrorMsg(data.message);
                        //         }
                        //     });
                    }}
                >
                    <SuccessMsg msg={successMsg} />

                    <div className="flex flex-col gap-0 text-start hover:text-orange-500">
                        <label className="text-lg font-normal px-1">
                            {" "}
                            Username{" "}
                        </label>
                        <input
                            autoComplete="off"
                            className="border-b border-neutral-700 hover:border-orange-500 active:border-orange-500 p-2 bg-neutral-900 outline-0 "
                            type="text"
                        />
                    </div>

                    <div className="flex flex-col gap-0 text-start hover:text-orange-500">
                        <label className="text-lg font-normal px-1">
                            {" "}
                            Email{" "}
                        </label>
                        <input
                            autoComplete="off"
                            className="border-b border-neutral-700 hover:border-orange-500 active:border-orange-500 p-2 bg-neutral-900 outline-0"
                            type="email"
                        />
                    </div>

                    <div className="flex flex-col gap-0 text-start hover:text-orange-500">
                        <label className="text-lg font-normal px-1">
                            {" "}
                            Password{" "}
                        </label>
                        <input
                            autoComplete="new-password"
                            className="border-b border-neutral-700 hover:border-orange-500 active:border-orange-500 p-2 bg-neutral-900 outline-0"
                            type="password"
                        />
                    </div>

                    <div className="flex flex-col gap-0 text-start hover:text-orange-500">
                        <label className="text-lg font-normal px-1">
                            {" "}
                            Confirm Password{" "}
                        </label>
                        <input
                            autoComplete="new-password"
                            className="border-b border-neutral-700 hover:border-orange-500 active:border-orange-500 p-2 bg-neutral-900 outline-0"
                            type="password"
                        />
                    </div>

                    <ErrorMsg msg={errorMsg} />

                    <button className="text-zinc-300 flex flex-row justify-between items-center hover:border-b border-stone-700 hover:text-orange-500 text-2xl font-light py-2">
                        Register your account
                        <span className="material-symbols-outlined hover:no-decoration">
                            arrow_forward
                        </span>
                    </button>

                    <div className="flex flex-col justify-center mt-2 gap-1">
                        <a
                            href="/login"
                            className="text-zinc-300 flex flex-row justify-between items-center hover:border-b border-stone-700 hover:text-orange-500 py-1 text-sm"
                        >
                            <span>Already registered? Login now</span>
                            <span className="material-symbols-outlined hover:no-decoration">
                                Login
                            </span>
                        </a>

                        <a
                            href="/register"
                            className="text-zinc-300 flex flex-row justify-between items-center hover:border-b border-stone-700 hover:text-orange-500 py-1  text-sm"
                        >
                            <span>Forgot password? </span>
                            <span className="material-symbols-outlined hover:no-decoration">
                                Help
                            </span>
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
}

function ErrorMsg(props) {
    if (props.msg) {
        return (
            <div className="flex flex-row justify-center items-center gap-1 text-red-500">
                <span className="material-symbols-outlined"> error </span>
                <span className="text-sm"> {props.msg} </span>
            </div>
        );
    } else {
        return <></>;
    }
}

function SuccessMsg(props) {
    if (props.msg) {
        return (
            <div className="flex flex-row justify-center items-center gap-1 text-green-500">
                <span className="material-symbols-outlined">
                    {" "}
                    check_circle{" "}
                </span>
                <span className="text-sm"> {props.msg} </span>
            </div>
        );
    } else {
        return <></>;
    }
}

export default Register;
