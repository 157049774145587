import React from 'react'
import { Navigate } from 'react-router-dom'

import ReactLoading from 'react-loading';

function Account(props) {

    return (
        <div>
            {props.user === null ? (
                <div className='flex flex-col gap-5 items-center justify-center grow'>
                    <ReactLoading type={'spinningBubbles'} color={'white'} height={64} width={64} />
                    <div className='text-2xl'>Loading ...</div>
                </div>
            ) : (
                props.user === false ? (
                    <Navigate to='/login' />
                ) :
                    <Navigate to='/profile' />
            )}
        </div>
    )

}

export default Account